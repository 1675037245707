/*
* Componente relativo alla modale di conferma
*/

import { Component, OnInit, EventEmitter } from '@angular/core';
@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent implements OnInit {
    constructor() { }
    ngOnInit() {
    }

    emitConfirmation(data) {
        if (data.confirmation instanceof EventEmitter) {
            data.confirmation.emit('CONFIRMED', data);
        }
        if (data.confirmAction && data.componentRef) {
            data.confirmAction(data.componentRef);
        }
    }

    emitSecondConfirmation(data) {
        if (data.secondConfirmation instanceof EventEmitter) {
            data.secondConfirmation.emit('CONFIRMED', data);
        }
        if (data.confirmSecondAction && data.componentRef) {
            data.confirmSecondAction(data.componentRef);
        }
    }
}
