import { environment } from "src/environments/environment";

/*
*    Servizio che costruisce e tornag li URL delle varie applicazioni
*/
export class UrlService {
    constructor() {
    }

    // Ritorna l'URL dell'applicazione
    getApplicationUrl() {
        let domain = "";
        let courseManagerUrl: any = {
            url: null,
            baseUrl: null,
            protocol: ''
        }
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        courseManagerUrl.protocol = window.location.protocol === "http:" ? "http" : "https";

        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            domain = "atfcm2.alloy.it";
        }
        else if ((window.location.hostname.indexOf("atfcm2") == 0 && firstDot > 0)) {
            domain = "atfcm2.alloy.it";
        }
        else if ((window.location.hostname.indexOf("atfdemocm2") == 0 && firstDot > 0)) {
            domain = "atfdemocm2.alloy.it";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            let url = window.location.hostname.substring(0, firstDot);
            if (url) {
                url = url + ".";
            }
            domain = url + secondLevelDomain;
        }
        else {
            // domain = "coursemanager." + secondLevelDomain;
            domain = window.location.hostname;
        }
        courseManagerUrl.baseUrl = courseManagerUrl.protocol + "://" + domain + "/";
        courseManagerUrl.url = courseManagerUrl.protocol + "://" + domain;

        return courseManagerUrl;
    }


    // Ritorna l'URL dell'applicazione Glp 2020
    getNewGlpUrl() {
        let thirdLevelDomain = "";
        let newGlpUrl: any = {
            url: null,
            protocol: ''
        }
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        newGlpUrl.protocol = window.location.protocol === "http:" ? "http" : "https";

        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            thirdLevelDomain = "localhost.";
        }
        else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
            thirdLevelDomain = "atf.";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            thirdLevelDomain = "test.";
        }
        else {
            thirdLevelDomain = "www.";
        }
        newGlpUrl.url = newGlpUrl.protocol + "://" + thirdLevelDomain + secondLevelDomain;

        return newGlpUrl;
    }

    // Ritorna l'URL dell'applicazione ENEL
    getEnelApplicationUrl() {
        let domain = "";
        let applicationUrlObj: any = {
            url: null,
            baseUrl: null,
            protocol: ''
        }
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        applicationUrlObj.protocol = window.location.protocol === "http:" ? "http" : "https";

        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            domain = "localhostcm2.alloy.it";
        }
        else if ((window.location.hostname.indexOf("atfcm2") == 0 && firstDot > 0)) {
            domain = "atfcm2.alloy.it";
        }
        else if ((window.location.hostname.indexOf("atfdemocm2") == 0 && firstDot > 0)) {
            domain = "atfdemocm2.alloy.it";
        }
        else {
            domain = window.location.hostname;
        }
        applicationUrlObj.baseUrl = applicationUrlObj.protocol + "://" + domain + ":" + port + "/";
        applicationUrlObj.url = applicationUrlObj.protocol + "://" + domain;

        return applicationUrlObj;
    }

    // Metodo che ritorna l'URL per della GLP
    getGLPURL(addDefaultDestinationPage?: boolean) {
        let protocol = "";
        let secondLevelDomain = "";
        let thirdLevelDomain = "";
        let url: string = null;
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        protocol = window.location.protocol === "http:" ? "http" : "https";
        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            thirdLevelDomain = "localhost.";
        }
        else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
            thirdLevelDomain = "atf.";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            thirdLevelDomain = "test.";
        }
        else {
            thirdLevelDomain = "www.";
        }

        url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

        /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
         * alla Library appenendoci il Token dell'utente (es http://localhost.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
         * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
        */
        if (addDefaultDestinationPage) {
            url += "/#/app/login";
        }
        return url;
    };

    getDEIURL(addDefaultDestinationPage?: boolean){
        let protocol = "";
        let secondLevelDomain = "";
        let thirdLevelDomain = "";
        let url: string = null;
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        protocol = window.location.protocol === "http:" ? "http" : "https";
        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            thirdLevelDomain = "localhost.";
        }
        else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
            thirdLevelDomain = "atf.";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            thirdLevelDomain = "test.";
        }
        else {
            // Questa è produzione, dove adesso il dominio della GLP è diverso da quello del CM, quindi lo forzo
            if (secondLevelDomain == "generalilearningplatform.com" || secondLevelDomain == "generalitrainingplatform.com") {
                thirdLevelDomain = "learningup.";
                secondLevelDomain = "generali.it";
            } else {
                thirdLevelDomain = "www.";
            }
        }

        url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

        /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
         * alla Library appenendoci il Token dell'utente (es http://localhost.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
         * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
        */
        if (addDefaultDestinationPage) {
            url += "/#/app/login";
        }
        return url;
    }

    // Metodo che ritorna l'URL per della Library
    getLibraryURL(addDefaultDestinationPage?: boolean) {
        let protocol = "";
        let secondLevelDomain = "";
        let thirdLevelDomain = "";
        let url: string = null;
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        protocol = window.location.protocol === "http:" ? "http" : "https";
        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            thirdLevelDomain = "localhostl.";
        }
        else if (window.location.hostname.indexOf("atf") == 0 && firstDot) {
            thirdLevelDomain = "atfl.";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            thirdLevelDomain = "testlibraryonline.";
        }
        else { // prima era www.
            thirdLevelDomain = "libraryonline."
        }

        url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

        /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
         * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
         * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
        */
        if (addDefaultDestinationPage) {
            url += "/#/app/login";
        }
        return url;
    };
    getHeritageURL(addDefaultDestinationPage?: boolean) {
        let protocol = "";
        let secondLevelDomain = "";
        let thirdLevelDomain = "";
        let url: string = null;
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        protocol = window.location.protocol === "http:" ? "http" : "https";
        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            thirdLevelDomain = "localhost.";
        }
        else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
            thirdLevelDomain = "atf.";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            thirdLevelDomain = "test.";
        }
        else {
            // Questa è produzione, dove adesso il dominio della GLP è diverso da quello del CM, quindi lo forzo
            if (secondLevelDomain == "generalilearningplatform.com" || secondLevelDomain == "generalitrainingplatform.com") {
                thirdLevelDomain = "learningup.";
                secondLevelDomain = "generali.it";
            } else {
                thirdLevelDomain = "www.";
            }
        }

        url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

        /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
         * alla Library appenendoci il Token dell'utente (es http://localhost.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
         * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
        */
        if (addDefaultDestinationPage) {
            url += "/#/app/login";
        }
        return url;
    };

    // Metodo che ritorna l'URL per della Library di Alleanza
    getLibraryAlleanzaURL(addDefaultDestinationPage?: boolean) {
        let protocol = "";
        let secondLevelDomain = "";
        let thirdLevelDomain = "";
        let url: string = null;
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        protocol = window.location.protocol === "http:" ? "http" : "https";
        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            thirdLevelDomain = "localhostla.";
        }
        else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
            thirdLevelDomain = "atfl.";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            thirdLevelDomain = "testalleanza.";
        }
        else { // prima era www.
            thirdLevelDomain = "alleanza."
        }

        url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

        /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
         * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
         * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
        */
        if (addDefaultDestinationPage) {
            url += "/#/app/login";
        }
        return url;
    };

    // Metodo che ritorna l'URL per della Library di DAS
    getLibraryDasURL(addDefaultDestinationPage?: boolean, isLocalLogin?: boolean) {
        let protocol = "";
        let secondLevelDomain = "";
        let thirdLevelDomain = "";
        let url: string = null;
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        protocol = window.location.protocol === "http:" ? "http" : "https";
        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            thirdLevelDomain = "localhostl.";
        }
        else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
            thirdLevelDomain = "atfl.";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            thirdLevelDomain = "testlibrarydas.";
        }
        else { // prima era www.
            thirdLevelDomain = "librarydas."
        }

        url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

        /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
         * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
         * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
        */
        if (addDefaultDestinationPage) {
            if (isLocalLogin) {
                url += "/#/app/localLogin";
            } else {
                url += "/#/app/login";
            }
        }
        return url;
    };

    // Ritorna l'URL SSO dell'applicazione Enel welfare
    getEnelSSOUrl(deviceType: string, userAgent: string, urlExtension?: string) {
        let domain = "";
        let ssoUrl: string = null;
        let ssoReturnPage: string = null;
        // Calcolo il dominio di appartenenza
        const protocol = window.location.protocol === "http:" ? "http" : "https";

        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        const lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            domain = "localhosts.alloy.it";
        }
        else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
            domain = "atfs.alloy.it";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            domain = "testenelsso." + secondLevelDomain;
        }
        else {
            domain = "enelsso." + secondLevelDomain;
        }
        ssoReturnPage = this.getEnelApplicationUrl().url;
        if (urlExtension && urlExtension.length) {
            ssoReturnPage = ssoReturnPage + urlExtension;
        };
        ssoUrl = protocol + "://" + domain + "/login?originApplicationName=" + encodeURIComponent("COURSEMANAGER") + "&applicationUrl=" + encodeURIComponent(ssoReturnPage) + "&deviceType=" + encodeURIComponent(deviceType) + "&userAgent=" + encodeURIComponent(userAgent);
        return ssoUrl;
    }

    // Ritorna l'URL dell'applicazione
    getSSOUrl(deviceType: string, userAgent: string, urlExtension?: string) {
        let domain = "";
        let ssoUrl: string = null;
        let ssoReturnPage: string = null;
        // Calcolo il dominio di appartenenza
        const protocol = window.location.protocol === "http:" ? "http" : "https";

        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        const lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            domain = "localhosts.alloy.it";
        }
        else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
            domain = "atfs.alloy.it";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            domain = "testsso." + secondLevelDomain;
        }
        else {
            domain = "sso." + secondLevelDomain;
        }
        ssoReturnPage = this.getApplicationUrl().url;
        if (urlExtension && urlExtension.length) {
            ssoReturnPage = ssoReturnPage + urlExtension;
        };
        ssoUrl = protocol + "://" + domain + "/login?originApplicationName=" + encodeURIComponent("COURSEMANAGER") + "&applicationUrl=" + encodeURIComponent(ssoReturnPage) + "&deviceType=" + encodeURIComponent(deviceType) + "&userAgent=" + encodeURIComponent(userAgent);
        return ssoUrl;
    }

    // Proxa l'url di un'immagine
    getExternalResourceName = (requestUrl: string, requestName: string) => {
        return this.getApplicationUrl().baseUrl + 'rest-api/coursemanager-mediator/proxy-url/' + encodeURIComponent(requestName) + '?requestUrl=' + encodeURIComponent(requestUrl);
    }

    // Ritorna l'URL dell'applicazione
    getMeetingUpApplicationUrl() {
        let domain = "";
        let courseManagerUrl: any = {
            url: null,
            baseUrl: null,
            protocol: ''
        }
        // Calcolo il dominio di appartenenza per chiamare i servizi Rest
        let port = window.location.protocol === "http:" ? 80 : 443;
        courseManagerUrl.protocol = window.location.protocol === "http:" ? "http" : "https";

        // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
        let firstDot = 0;
        let lastDot = window.location.hostname.lastIndexOf(".");
        if (lastDot != -1) {
            firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
        }
        if (firstDot == -1) {
            firstDot = 0;
        }
        let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
        if (secondLevelDomain.charAt(0) == ".") {
            secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
        }
        // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
        if (window.location.hostname.indexOf("localhost") == 0) {
            domain = "localhostmeetingup.alloy.it";
        }
        else if ((window.location.hostname.indexOf("atfcm2") == 0 && firstDot > 0)) {
            domain = "atfcm2.alloy.it";
        }
        else if ((window.location.hostname.indexOf("atfdemocm2") == 0 && firstDot > 0)) {
            domain = "atfdemocm2.alloy.it";
        }
        else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
            domain = "testcoursemanager." + secondLevelDomain;
        }
        else {
            // domain = "coursemanager." + secondLevelDomain;
            domain = window.location.hostname;
        }
        courseManagerUrl.baseUrl = courseManagerUrl.protocol + "://" + domain + ":" + port + "/";
        courseManagerUrl.url = courseManagerUrl.protocol + "://" + domain;

        return courseManagerUrl;
    }

}